import React from 'react'

import { DashboardPage } from '../components'

class Index extends React.Component {
  render() {
    return <DashboardPage />
  }
}

export default Index
